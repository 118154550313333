import axios from 'axios';
import {
    COMMON_CONSTANTS,
    COMMON_COMMENTS,
    MENU_ITEMS,
    CLIENT_LIST_ALL,
    USER_ROLES,
    LEGAL_ENTITIES,
    COUNTRIES,
    TIMEZONES,
    TIME_OFFSETS,
    ERROR,
    DEFAULT_NON_BPSP_PRICING_REQUEST,
    DEFAULT_NON_BPSP_PRICING_SUCCESS,
    DEFAULT_NON_BPSP_PRICING_FAILURE,
    GENERAL_CONFIG,
    GENERAL_CONFIGURATION,
    CLIENT_LIST_WITH_RISK_LEVEL
} from './types';
import { get } from '../utils/request';
import { fetchingCommonConstants } from '../reducers/constants-reducer';
import { defaultNonBPSPPricing } from '../utils/constants';
import { isEmpty } from 'lodash';
export const selectCurrencies = ({ constants }) =>
    constants?.commonConstants?.currencies ?? [];
export function getCommonConstants() {
    return async function (dispatch) {
        try {
                const res = await get('/common/constants', fetchingCommonConstants);
                dispatch({
                    type: COMMON_CONSTANTS,
                    payload: res.data,
                });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: {
                    message: err?.response?.data?.message,
                },
            });
        }
    };
}

export function getCommonComments() {
    return async function (dispatch) {
        try {
            const res = await axios.get('/common/constants/commonComments');
            dispatch({
                type: COMMON_COMMENTS,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: {
                    message: err?.response?.data?.message,
                },
            });
        }
    };
}

export function getMenuItems() {
    return async function (dispatch) {
        try {
            const res = await axios.get('/settings/menu');
            dispatch({
                type: MENU_ITEMS,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: {
                    message: err?.response?.data?.message,
                },
            });
        }
    };
}

export function getClientListAll() {
    return async function (dispatch) {
        try {
            const res = await axios.get('/settings/client/bulk');
            dispatch({
                type: CLIENT_LIST_ALL,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: {
                    message: err?.response?.data?.message,
                },
            });
        }
    };
}

export function getUserRoles() {
    return async function (dispatch) {
        try {
            const res = await axios.get('/settings/role/bulk');
            dispatch({
                type: USER_ROLES,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: {
                    message: err?.response?.data?.message,
                },
            });
        }
    };
}

export function getLegalEntities() {
    return async function (dispatch, getState) {
        try {
            const legalEntities = getState().constants.legalEntities;
            if(isEmpty(legalEntities)) {
                const res = await axios.get('/common/constants/legalEntities');
                dispatch({
                    type: LEGAL_ENTITIES,
                    payload: res.data,
                });
            }
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: {
                    message: err?.response?.data?.message,
                },
            });
        }
    };
}

export function getCountries() {
    return async function (dispatch) {
        try {
            const res = await axios.get(
                '/common/constants/countryCodesAndFullNamesMap'
            );
            dispatch({ type: COUNTRIES, payload: res.data });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: {
                    message: err?.response?.data?.message,
                },
            });
        }
    };
}

export function getTimeZones() {
    return async function (dispatch) {
        try {
            const res = await axios.get('/common/constants/timezones');

            dispatch({ type: TIMEZONES, payload: res.data });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: {
                    message: err?.response?.data?.message,
                },
            });
        }
    };
}

export function getTimeOffsets() {
    return async function (dispatch) {
        try {
            const res = await axios.get('/common/constants/timezoneOffsets');
            dispatch({ type: TIME_OFFSETS, payload: res.data });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: {
                    message: err?.response?.data?.message,
                },
            });
        }
    };
}

export function getNonBPSPDefaultPricing() {
    return async function (dispatch) {
        try {
            dispatch({ type: DEFAULT_NON_BPSP_PRICING_REQUEST });
            const response = await axios.get(`/common/constants/defaultPricing/nonBPSP`);
            dispatch({ type: DEFAULT_NON_BPSP_PRICING_SUCCESS, payload: response.data });
        } catch (err) {
            dispatch({
                type: DEFAULT_NON_BPSP_PRICING_FAILURE,
                payload: {
                    message: err?.response?.data?.message,
                },
            });
        }
    }
}

export function getGeneralConfiguration() {
    return async function (dispatch) {
        try {
            const res = await axios.get('/generalConfiguration/VIRTUAL_ACCOUNT_ALLOWED_LEGAL_ENTITY');
            dispatch({
                type: GENERAL_CONFIG,
                payload: res.data.value,
            });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: {
                    message: err?.response?.data?.message,
                },
            });
        }
    };
}

export function getAllClientWithRiskLevel() {
    return async function (dispatch) {
        try {
            const res = await axios.get('/complianceConfiguration/getAllClientRiskLevel');
            dispatch({
                type: CLIENT_LIST_WITH_RISK_LEVEL,
                payload: res.data,
            })
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: {
                    message: err?.response?.data?.message
                }
            })
        }
    }
}

export function getGeneralConfig(key) {
    return async function (dispatch) {
        try {
            const res = await axios.get(`/generalConfiguration/${key}`);
            dispatch({
                type: GENERAL_CONFIGURATION,
                payload: res.data.value,
            });
        } catch (err) {
            dispatch({
                type: ERROR,
                payload: {
                    message: err?.response?.data?.message,
                },
            });
        }
    };
}